import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

export default function ParticlesBackground(){
    const [init, setInit] = useState(false);
	useEffect(() => {
		initParticlesEngine(async (engine) => {
			await loadSlim(engine);
		}).then(() => {
			setInit(true);
		});
	}, []);

	const particlesLoaded = (container) => {
		console.log(container);
	};

	const options = useMemo(
		() => ({
		fullScreen: false,
		fpsLimit: 60,
		interactivity: {
			events: {
				onClick: {
					enable: false,
					mode: " ",
				},
				onHover: {
					enable: false,
					mode: " ",
				},
			},
			modes: {
				push: {
					quantity: 5,
				},
				repulse: {
					distance: 200,
					duration: 0.4,
				},
			},
		},
		particles: {
			color: {
				value: "#ffffff",
			},
			links: {
				color: "#ffffff",
				distance: 150,
				enable: true,
				opacity: 0.5,
				width: 3,
			},
			move: {
				direction: "none",
				enable: true,
				outMode: "bounce",
				random: true,
				speed: 3,
				straight: false,
			},
			number: {
				density: {
					enable: true,
				},
				value: 80,
			},
			opacity: {
				value: 0.7,
			},
			shape: {
				type: "circle",
			},
			size: {
				value: { min: 3, max: 10 },
			},
		},
		detectRetina: true,
		}),[],
	);
	if (init) {
		return (
		  <Particles
			id="tsparticles"
			particlesLoaded={particlesLoaded}
			options={options}
		  />
		);
	}
	
	return <></>;

}