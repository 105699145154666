import {useState} from "react";
import Modal from "./modal/modal";


const AboutUsCards = (props) => {
    const [activeModal, setActiveModal] = useState(-1);

    const handleClick = (id) =>{
        setActiveModal(id);
        document.body.style.overflow = 'hidden';
    }

    const closeModal = () =>{
        setActiveModal(-1);
        document.body.style.overflow = 'auto';
    }

    return (
        <section className="aboutUsCards">
            {[0, 1, 2].map((i) => (
                <div key={i}>
                    <button onClick={() => handleClick(i)}>
                        <img src={props[i].cardImage} alt={props[i].cardName} />
                        <h3>{props[i].cardName}</h3>
                    </button>
                    {activeModal === i && (
                        <Modal modal={activeModal !== null} closeModal={closeModal} titleModal={props[i].cardName}>
                           {props[i].cardContent}
                        </Modal>
                    )}
                </div>
            ))}
        </section>
    );
};

export default AboutUsCards;