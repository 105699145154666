import Slide from "../components/slide";
import AboutCards from "../components/aboutcards";

export default function About(){
    const reel = {images:['images/Black_MW.png','images/Blue_MW.png']};
    const content = {
        data:[
            {
                cardName:'Identidad',
                cardImage:'images/about/identify.svg',
                cardContent:`
                    <h5>Misi&oacute;n</h5>
                    <p>Satisfacer a nuestros clientes con excelencia en las soluciones log&iacute;sticas.</p>
                    <br/>
                    <h5>Visi&oacute;n</h5>
                    <p>Llegar a las familias y empresas mexicanas transportando productos de su necesidad, al ser conscientes de la actividad esencial y sensible, con el mejor costo beneficio</p>
                    <br/>
                    <h5>Valores</h5>
                    <ul>
                        <li>valor</li>
                        <li>valor</li>
                        <li>valor</li>
                    </ul>             
                `
            },
            {
                cardName:'Cobertura',
                cardImage:'images/about/coverage.svg',
                cardContent:`
                    <h5>Titulo</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris imperdiet fringilla urna, a efficitur tortor ultrices id. Nunc ultrices non velit scelerisque gravida. Ut eget neque non mi lacinia vehicula vitae in tellus. Nunc ullamcorper molestie arcu, vel posuere odio tincidunt eu. In hac habitasse platea dictumst. Phasellus convallis hendrerit imperdiet. Integer vehicula tristique auctor. Nulla mattis porttitor nulla, at dignissim lacus feugiat a.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris imperdiet fringilla urna, a efficitur tortor ultrices id. Nunc ultrices non velit scelerisque gravida. Ut eget neque non mi lacinia vehicula vitae in tellus. Nunc ullamcorper molestie arcu, vel posuere odio tincidunt eu. In hac habitasse platea dictumst. Phasellus convallis hendrerit imperdiet. Integer vehicula tristique auctor. Nulla mattis porttitor nulla, at dignissim lacus feugiat a.</p>
                    <img src="https://cuentame.inegi.org.mx/mapas/images/mapa_sensible/mapa_nal.gif" alt="Republica Mexicana"/>
                `
            },
            {
                cardName:'Experiencia',
                cardImage:'images/about/experience.svg',
                cardContent:`
                    <div class="carousel">
                        <div><img src="images/experience/grupo_expansion.png" alt=""/></div>
                        <div><img src="images/experience/hellmann.png" alt=""/></div>
                        <div><img src="images/experience/telcel.png" alt=""/></div>
                        <div><img src="images/experience/puratos.png" alt=""/></div>
                        <div><img src="images/experience/vans.png" alt=""/></div>
                        <div><img src="images/experience/grupo_planeta.svg" alt=""/></div>
                        <div><img src="images/experience/banamex.png" alt=""/></div>
                        <div><img src="images/experience/brightstar.png" alt=""/></div>
                        <div><img src="images/experience/farmamedica.png" alt=""/></div>
                        <div><img src="images/experience/walmart.png" alt=""/></div>
                        <div><img src="images/experience/mercadolibre.png" alt=""/></div>
                    </div>
                `
            },
        ],
    }

    return(
        <>
        <section className="titlePage">
            <div className="slide">
                    <Slide reel={reel}/>
            </div>
            <div className="titleText">
                <div>
                    <h2>Nosotros</h2>
                    <br/>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam congue luctus magna nec convallis. Sed condimentum aliquam erat, eu egestas ipsum vulputate a.</p>
                </div>
            </div>
        </section>
        <AboutCards {...content.data}/>
        </>
    )
}