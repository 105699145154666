import {useState} from "react";
import { Link } from "react-router-dom";

export default function Header(){
    const [isActive, setIsActive] = useState(false);

    const handleClick = (event) => {
        event.stopPropagation();
        setIsActive(prevState => !prevState);
    };
    
    return(
        <header id="header" className="header">
        <Link to="/" id="home-logo">
            <section className="logo">
                <div className="logoZellet"></div>
                <h1>ELLET</h1>
            </section>
        </Link>
        <nav className={`menuContainer ${isActive ? 'active':''}`} onClick={(e) => handleClick(e)}>
            <ul className={`navigation ${isActive ? 'active':''}`} onClick={(e) => handleClick(e)}>
                <li><Link to="/" id="home">Inicio</Link></li>
                <li><Link to="/Nosotros" id="about">Nosotros</Link></li>
                <li><Link to="/Contacto" id="contact">Contacto</Link></li>
            </ul>
        </nav>
        <button className={`hamburger ${isActive ? 'active':''}`} onClick={(e) => handleClick(e)}>
            <span></span>
        </button>
    </header>
    )
}