import Slide from "../components/slide";
import { useState } from "react";

export default function Contact() {
    const formInitialDetails = {
        name: "",
        email: "",
        phone: "",
        company: "",
        message: "",
    };

    const [formDetails, setFormDetails] = useState(formInitialDetails);
    const [buttonText, setButtonText] = useState("Enviar");
    const [status, setStatus] = useState({});

    const updateForm = (category, value) => {
        setFormDetails({
            ...formDetails,
            [category]: value
        });
    };

    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const validatePhone = (phone) => /^\d+$/.test(phone);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateEmail(formDetails.email)) {
            setStatus({ error: "true", message: "Email no válido" });
            return;
        }
        if (!validatePhone(formDetails.phone)) {
            setStatus({ error: "true", message: "Teléfono no válido" });
            return;
        }

        setButtonText("Enviando...");
        let result = await sendForm(formDetails);

        if (result && result.code === 200) {
            setButtonText('Enviado');
            setStatus({ error: "false", message: "Gracias por su envío. Nos pondremos en contacto con usted a la brevedad." });
            setFormDetails(formInitialDetails);
        } else {
            setStatus({ error: "true", message: "Algo salió mal, inténtelo más tarde." });
        }

        setTimeout(() => {
            setButtonText('Enviar');
            setStatus({ message: "" });
        }, 15000);
    };

    const sendForm = async (formDetails) => {
        try {
            let res = await fetch("/contact", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=utf-8"
                },
                body: JSON.stringify(formDetails),
            });
            if (!res.ok) {
                throw new Error(`Error: ${res.status}`);
            }
            return await res.json();
        } catch (error) {
            console.error("Error al enviar el formulario:", error);
            return null; // Return null on error
        }
    };

    const reel = { images: ['images/Black_MW.png', 'images/Blue_MW.png'] };
    return (
        <>
            <section className="titlePage">
                <div className="slide">
                    <Slide reel={reel} />
                </div>
                <div className="titleText">
                    <div>
                        <h2>Contacto</h2>
                        <br />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam congue luctus magna nec convallis. Sed condimentum aliquam erat, eu egestas ipsum vulputate a.</p>
                    </div>
                </div>
            </section>
            <section className="contactContainer">
                <div>
                    {status.message && <p className="response-email active" error-server={status.error}>{status.message}</p>}
                    <form className="formContainer" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="name">Nombre</label>
                            <input
                                id="name"
                                type="text"
                                value={formDetails.name}
                                placeholder="Nombre"
                                onChange={(e) => updateForm("name", e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="email">E-mail</label>
                            <input
                                id="email"
                                type="email"
                                value={formDetails.email}
                                placeholder="E-mail"
                                onChange={(e) => updateForm("email", e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="company">Empresa</label>
                            <input
                                id="company"
                                type="text"
                                value={formDetails.company}
                                placeholder="Empresa"
                                onChange={(e) => updateForm("company", e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="phone">Tel&eacute;fono</label>
                            <input
                                id="phone"
                                type="text"
                                value={formDetails.phone}
                                placeholder="Teléfono"
                                onChange={(e) => updateForm("phone", e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="message">Mensaje</label>
                            <textarea
                                id="message"
                                value={formDetails.message}
                                placeholder="Mensaje"
                                onChange={(e) => updateForm("message", e.target.value)}
                                required
                            />
                        </div>
                        <div className="button-submit"><button type="submit">{buttonText}</button></div>
                    </form>
                </div>
                <div className="auxContactContainer">
                    <h3>Informaci&oacute;n de contacto</h3>
                    <ul>
                        <li>
                            <span>
                                Carlos Arellano Romero
                                <br />551 965 8897
                            </span>
                            <span>
                                Ricardo Téllez Sánchez
                                <br />561 269 1736
                            </span>
                            <span>
                                Atención a clientes
                                <br />552 673 7612
                            </span>
                        </li>
                        <li>info@logisticazellet.com.mx</li>
                        <li>M&eacute;xico</li>
                    </ul>
                </div>
            </section>
        </>
    );
}