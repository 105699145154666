export default function Footer(){
    return(
        <footer id="footer" className="footer">
            <div className="logo">
                <div className="logoZellet"></div>
                <div>
                    <h2>ELLET</h2>
                    <span>Soluciones en log&iacute;stica</span>
                </div>
            </div>
            <div className="followUs">
                <span>Síganos</span>
                <div
                    ><img src="images/social/facebook.svg" alt="Facebook"/>
                    <img src="images/social/x.svg" alt="x"/>
                    <img src="images/social/instagram.svg" alt="Instagram"/>
                    <img src="images/social/in.svg" alt="Linkedin"/>
                </div>
            </div>
        </footer>
    )
}

