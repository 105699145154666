import { useState , useEffect, useRef} from "react";  

export default function Slide({reel}){
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [count, setCount] = useState(0);
    const imgsrc = useRef(null);

    useEffect(() => {
        if(reel.images.length === 0)
            return;

        imgsrc.current.src = reel.images[count];

        const uploadimg = setInterval(() => {
            setIsTransitioning(true);
            setTimeout(() => {
                setIsTransitioning(false);
                setCount(index => (index + 1) % reel.images.length);
            },480);
        },4000);
        return () => clearInterval(uploadimg);

    },[count,reel.images,isTransitioning]);

    return(
        <>
            <div id="slideShow">
                <img ref={imgsrc} className={`slider-image ${isTransitioning ? 'fade':''}`} src={reel.images[count]} alt="Slide"/>
            </div>
        </>
    );
}