import ParticlesBackground from "../components/particles/particles";
import Slide from "../components/slide"
import { Link } from "react-router-dom";


export default function Home(){
    const reel = {images:['images/Black_MW.png','images/Blue_MW.png']};
    return(
        <>
            <section className="animationHome">
                <div>
                    <h2>Tu necesidad, es m&iacute; entrega</h2>
                    <h3>Soluciones en log&iacute;stica</h3>
                </div>
                <ParticlesBackground/>
            </section>
            <section className="ourServicesContainer">
                <h2>Nuestros Servicios</h2>
                <div className="serviceCard">
                    <div>
                        <div>
                            <img src="images/services/paqueteria.svg" alt="Mensajería y paquetería especializada"/>
                        Mensajer&iacute;a y paqueter&iacute;a especializada
                        </div>
                    </div>
                    <div>
                        <div>
                        <img src="images/services/unidades.svg" alt="Unidades dedicadas"/>
                            Unidades dedicadas
                        </div>
                    </div>
                    <div>
                        <div>
                        <img src="images/services/entrega.svg" alt="Envíos consolidados"/>
                        Env&iacute;os consolidados
                        </div>
                    </div>
                    <div>
                        <div>
                            <img src="images/services/almacenaje.svg" alt="Almacenaje y maquila"/>
                            Almacenaje y maquila
                        </div>
                    </div>
                    <div>
                        <div>
                            <img src="images/services/ultimamilla.svg" alt="Servicio última milla nivel nacional"/>
                            Servicio &uacute;ltima milla nivel nacional
                        </div>
                    </div>
                </div>
            </section>
            <section className="ourClientsContainer">
                <h2>Marcas que Respaldan Nuestro Compromiso</h2>
                <div>
                    <div><img src="images/clients/atlas.png" alt="Atlas"/></div>
                    <div><img src="images/clients/vento.png" alt="Vento"/></div>
                </div>
            </section>
            <section className="titlePage">
                <div className="slide" top-radious="true">
                    <Slide reel={reel}/>
                </div>
                <div className="titleText">
                    <div>
                        <h2>Conocenos</h2>
                        <p>Somos una organizaci&oacute;n mexicana que brinda servicios especializados log&iacute;sticos enfocados en las necesidades de cada cliente.
                        <br/>
                        <Link to="/Nosotros">Saber m&aacute;s...</Link></p>
                    </div>
                </div>
            </section>
        </>
    )
}