const Modal = ({children,modal,closeModal,titleModal}) => {
    const handleModalContainer = (event) => event.stopPropagation();

    return(
        <dialog className={`${modal === true ? 'activeModal' : ''}`} onClick={closeModal}>
            <div className="modal-container" onClick={handleModalContainer}>
                <h4>{titleModal}</h4>
                <div className="text-modal-container" dangerouslySetInnerHTML={{ __html: children }}/>
                <button onClick={closeModal}></button>
            </div>
        </dialog>
    );
};

export default Modal;

