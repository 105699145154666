import React, {useEffect} from "react";
import { Routes, Route, useLocation} from 'react-router-dom';
import './index.css';
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import Header from './components/header';
import Footer from './components/footer';



function App() {
	const location = useLocation();

	useEffect(() => {
        fetch("/connect")
        .then((res) => res.json())
        .then((data) => {console.log(data)})
    }, []);

	useEffect(() => {
		window.scrollTo(0,0);
		document.body.style.overflow = 'auto';
	},[location]);

  return (
	<>
		<Header/>
		<main id="main" className="main">
			<Routes>
				<Route index element={<Home/>}/>
				<Route path="/Inicio" element={<Home/>}/>
				<Route path="/Nosotros" element={<About/>}/>
				<Route path="/Contacto" element={<Contact/>}/>
			</Routes>
		</main>
		<Footer/>
	</>
  );
}

export default App;
